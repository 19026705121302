import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import abi from "./abi.json";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const countDownDate = new Date("May 14,2022 16:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();

      var d = new Date();
      var utc_offset = d.getTimezoneOffset();
      d.setMinutes(d.getMinutes() + utc_offset);

      const distance = countDownDate - d;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xB104d74F1638D23366a7A791d5E9fEFa28FbcdbD"
    );

    setInstance(ins);
    startTimer();

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/mintcryptofaddicts.io/");
      alert("Meta Mask not detected");
    }
  }

  // Create a provider to interact with a smart contract
  async function connectWallet() {
    if (typeof window.ethereum !== "undefined") {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 20) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <div className="mint-page">
      <Container>
        <div className="navb">
          {/* <a href="" target="_blank" rel="noreferrer"> */}
          <div className="text-center">
            {/* <img
              className="img-fluid logo"
              src={require("./assets/img/logo.webp").default}
              alt=""
            /> */}
            &nbsp;
          </div>

          {/* </a> */}
          <div className=" text-center d-flex align-items-center rmt">
            <div className="">
              <a
                href="https://cryptofaddicts.io/
"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid si"
                  src={require("./assets/img/globe.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://opensea.io/collection/crypto-faddicts"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="img-fluid si"
                  src={require("./assets/img/Opensea.png").default}
                  alt=""
                />
              </a>
            </div>
            <div className="">
              <div onClick={requestAccount} className="btn cusbtn">
                <span className="font-weight-bold text-uppercase">
                  {add ? add.substring(0, 15) : "Connect Wallet"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="mintbox pt-5 pb-5">
        <Container className="d-flex justify-content-center">
          <div className="mint">
            <div className="">
              <h1 className="mih1 text-center  text-uppercase mb-3">
                Mint Your faddicts
              </h1>
              <p className="subtitle p-0 m-0 text-center  text-uppercase">
                Max 20 per wallet and transaction
              </p>

              {/* <div className="subtitle p-0 m-0 text-center  text-uppercase">
                Presale Ends in:{" "}
                <Container
                  className="countd d-flex align-items-end justify-content-center h-50"
                  fluid
                >
                  <Row>
                    <Col lg="12">
                      <div className="text-white">
                        <Row className="">
                          <Col>
                            <Row>
                              <Col>
                                <h1 className="showcase-title">{timerDays}</h1>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <h5 className="text-uppercase showcase-subtitle">
                                    Days
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <h1 className="showcase-title">{timerHours}</h1>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <h5 className="text-uppercase showcase-subtitle">
                                    Hours
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <h1 className="showcase-title">
                                  {timerMinutes}
                                </h1>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <h5 className="text-uppercase showcase-subtitle">
                                    Minutes
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col>
                                <h1 className="showcase-title showcase-subtitle">
                                  {timerSeconds}
                                </h1>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <h5 className="text-uppercase">Seconds</h5>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
             */}

              <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="mx-3">
                  <AiFillMinusCircle onClick={minus} size={60} />
                </div>
                <div className="display-number d-flex justify-content-center align-items-center">
                  <span className="number">{mintNumber}</span>
                </div>
                <div className="mx-3">
                  <AiFillPlusCircle onClick={plus} size={60} />
                </div>
              </div>
              <div className="text-center mt-5">
                <button
                  onClick={mint}
                  className="mintbutton btn  text-uppercase"
                >
                  Mint Now
                </button>
              </div>
              <div className="text-center mt-4">
                <h5 className="font-weight-bold bfont">
                  Price: {mintNumber * 0.05} ETH
                </h5>
              </div>
              <div className="text-center mt-4">
                <h3 className="font-weight-bold bfont">
                  {" "}
                  {totalMinted} / 2500
                </h3>
              </div>

              <div className="text-center mt-3">
                <h5 className="font-weight-bold bfont">Public Sale Round 1</h5>
              </div>

              <div className="text-center mt-4">
                <div className="">
                  <a
                    href="https://cryptofaddicts.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/globe.png").default}
                      alt=""
                    />
                  </a>
                  <a
                    href="https://opensea.io/collection/crypto-faddicts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid si"
                      src={require("./assets/img/Opensea.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default App;
